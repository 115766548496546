import React, { Component } from "react";
import { FaDownload } from "react-icons/fa";
import ModalTemplateCostingDownload from "../components/ModalTemplateCostingDownload";
import ModalTemplateDeliveryDownload from "../components/ModalTemplateDeliveryDownload";
import ModalTemplateQualityDownload from "../components/ModalTemplateQualityDownload";
import ModalTemplateSustainabilityDownload from "../components/ModalTemplateSustainabilityDownload";


class CardTemplateDownload extends Component {

    render() {

        return (

            <div class="card border-white">
              <div class="card-body">
                <h6 class="card-title">
                    <FaDownload />&nbsp;Manufacturing2 Index Template Download
                </h6>
                <div class="border-top my-2"></div>
                <p class="card-text">
                    Download manufacturing index template file for the active reporting period.
                    Add or edit the manufacturing index data of the factories and upload the template
                    file.
                </p>
                <button class="btn  btn-outline-primary btn-sm" role="button" data-toggle="modal" data-target={this.props.template}>
                    Download
                </button>
            
              </div>

               <ModalTemplateCostingDownload />
               <ModalTemplateDeliveryDownload />
               <ModalTemplateQualityDownload />
               <ModalTemplateSustainabilityDownload />
              
            </div>
            
        );
    };
};

export default CardTemplateDownload;