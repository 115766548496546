
const Local = {
    ClientId: "nike.gsm.mi",
    OktaAuthorizeUrl: "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/qa/token",
    AuditListUrl : "http://localhost:8081/auditlist",
    ContactListUrl : "http://localhost:8081/contactlist",
    CostingReadUrl : "http://localhost:8081/costingread",
    CostingSaveUrl : "http://localhost:8081/costingsave", 
    CostingTemplateUrl : "http://localhost:8081/costingtemplate",
    DataReviewListUrl : "http://localhost:8081/datareviewlist",
    DataReviewStatusUrl : "http://localhost:8081/datareviewstatus",
    DeliveryReadUrl : "http://localhost:8081/deliveryread",
    DeliverySaveUrl : "http://localhost:8081/deliverysave",
    DeliveryTemplateUrl : "http://localhost:8081/deliverytemplate",
    ExcludeFactoryUrl : "http://localhost:8081/excludefactory",
    ExportUrl : "http://localhost:8081/export",
    FactoryListUrl : "http://localhost:8081/factorylist",
    FactoryStatisticsListUrl : "http://localhost:8081/factorystatisticslist",
    FactoryStatusListUrl : "http://localhost:8081/factorystatuslist",
    HistoricalDataListUrl : "http://localhost:8081/historicaldatalist",
    ImportDeliveryDataUrl : "http://localhost:8081/importdeliverydata",
    ImportFactoryDataUrl : "http://localhost:8081/importfactorydata",
    ImportSustainabilityDataUrl : "http://localhost:8081/importsustainabilitydata",
    IncludeFactoryUrl : "http://localhost:8081/includefactory",
    LockReportingPeriodUrl : "http://localhost:8081/lockreportingperiod",
    QualityReadUrl : "http://localhost:8081/qualityread",
    QualitySaveUrl : "http://localhost:8081/qualitysave",
    QualityTemplateUrl : "http://localhost:8081/qualitytemplate",
    ReportingPeriodListUrl : "http://localhost:8081/reportingperiodlist",
    ReportingStatusListUrl : "http://localhost:8081/reportingstatuslist",
    SetActiveReportingPeriodUrl : "http://localhost:8081/setactivereportingperiod",
    SustainabilityReadUrl : "http://localhost:8081/sustainabilityread",
    SustainabilitySaveUrl : "http://localhost:8081/sustainabilitysave",
    SustainabilityTemplateUrl : "http://localhost:8081/sustainabilitytemplate",
    TemplateUploadUrl : "http://localhost:8081/templateupload",
    UnlockReportingPeriodUrl : "http://localhost:8081/unlockreportingperiod"
    
};
const Dev = {
    ClientId: "nike.gsm.mi",
    OktaAuthorizeUrl: "https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/qa/token",
    AuditListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/auditlist",
    ContactListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/contactlist",
    CostingReadUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/costingread",
    CostingSaveUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/costingsave", 
    CostingTemplateUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/costingtemplate",
    DataReviewListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/datareviewlist",
    DataReviewStatusUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/datareviewstatus",
    DeliveryReadUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/deliveryread",
    DeliverySaveUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/deliverysave",
    DeliveryTemplateUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/deliverytemplate",
    ExcludeFactoryUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/excludefactory",
    ExportUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/export",
    FactoryListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/factorylist",
    FactoryStatisticsListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/factorystatisticslist",
    FactoryStatusListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/factorystatuslist",
    HistoricalDataListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/historicaldatalist",
    ImportDeliveryDataUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/importdeliverydata",
    ImportFactoryDataUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/importfactorydata",
    ImportSustainabilityDataUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/importsustainabilitydata",
    IncludeFactoryUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/includefactory",
    LockReportingPeriodUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/lockreportingperiod",
    QualityReadUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/qualityread",
    QualitySaveUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/qualitysave",
    QualityTemplateUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/qualitytemplate",
    ReportingPeriodListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/reportingperiodlist",
    ReportingStatusListUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/reportingstatuslist",
    SetActiveReportingPeriodUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/setactivereportingperiod",
    SustainabilityReadUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/sustainabilityread",
    SustainabilitySaveUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/sustainabilitysave",
    SustainabilityTemplateUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/sustainabilitytemplate",
    TemplateUploadUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/templateupload",
    UnlockReportingPeriodUrl : "https://ez0uv42vt6.execute-api.us-west-2.amazonaws.com/dev/unlockreportingperiod"
    
};


const Prod = {
    ClientId: "nike.gsm.mi",
    OktaAuthorizeUrl: "https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7",
    OktaTokenUrl: "https://api.aegis.nikecloud.com/v1/prod/token",
    AuditListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/auditlist",
    ContactListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/contactlist",
    CostingReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/costingread",
    CostingSaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/costingsave", 
    CostingTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/costingtemplate",
    DataReviewListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/datareviewlist",
    DataReviewStatusUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/datareviewstatus",
    DeliveryReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/deliveryread",
    DeliverySaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/deliverysave",
    DeliveryTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/deliverytemplate",
    ExcludeFactoryUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/excludefactory",
    ExportUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/export",
    FactoryListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/factorylist",
    FactoryStatisticsListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/factorystatisticslist",
    FactoryStatusListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/factorystatuslist",
    HistoricalDataListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/historicaldatalist",
    ImportDeliveryDataUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/importdeliverydata",
    ImportFactoryDataUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/importfactorydata",
    ImportSustainabilityDataUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/importsustainabilitydata",
    IncludeFactoryUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/includefactory",
    LockReportingPeriodUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/lockreportingperiod",
    QualityReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/qualityread",
    QualitySaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/qualitysave",
    QualityTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/qualitytemplate",
    ReportingPeriodListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/reportingperiodlist",
    ReportingStatusListUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/reportingstatuslist",
    SetActiveReportingPeriodUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/setactivereportingperiod",
    SustainabilityReadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/sustainabilityread",
    SustainabilitySaveUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/sustainabilitysave",
    SustainabilityTemplateUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/sustainabilitytemplate",
    TemplateUploadUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/templateupload",
    UnlockReportingPeriodUrl : "https://s0lqcq8bbf.execute-api.us-west-2.amazonaws.com/prod/unlockreportingperiod"
    
};

//Change this configuration based on the environment the site is deployed.
//The configuration values are Dev and Prod
const Config = Dev;

module.exports = Config;
